import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DetailText = styled.div`
  font-size: 38px;
  text-align: center;
  margin-top: 30px;
`

class IndexPage extends React.Component {
  render() {
    return (
      <Layout hideWhereTo={true}>
        <SEO title="Sara & Tim" />
        <DetailText>October 23rd 2021</DetailText>
        <DetailText>Upwaltham Barns, GU28 0LX</DetailText>
      </Layout>
    )
  }
}

export default IndexPage
